<template>
    <div>
        <h1>Dashboard</h1>

        <t-card
            v-for="menuItem in menuItems" :key="menuItem.label"
        >
            <h2 class="border-b border-edge-primary pb-4 mt-0 flex">
                <base-icon :name="menuItem.icon" class="w-6 h-6 mr-2 flex-none" />
                {{ menuItem.label }}
            </h2>
            <dl>
                <template v-for="(subMenuItem, index) in menuItem.children">
                    <dt :key="index + '_dt'" class="float-left w-1/2 sm:w-1/3 clear-left mt-4">
                        {{ subMenuItem.label }}
                    </dt>
                    <dd :key="index + '_dd'" class="float-left w-1/2 sm:w-2/3 text-bold-text font-semibold mt-4">
                        <router-link
                            class="mr-2 mb-1 flex-row items-center btn-liq-default-sm inline-block whitespace-nowrap"
                            :to="subMenuItem.listRoute"
                        >
                            <base-icon name="viewList" class="w-4 h-4 mr-1 -ml-1" />
                            List
                        </router-link>

                        <router-link
                            class="mr-2 mb-1 flex-row items-center btn-liq-default-sm inline-block whitespace-nowrap"
                            :to="subMenuItem.createRoute"
                        >
                            <base-icon name="plus" class="w-4 h-4 mr-1 -ml-1" />
                            Create
                        </router-link>
                    </dd>
                </template>
            </dl>
            <div class="clear-both" />
        </t-card>
    </div>
</template>

<script>
import BaseIcon from "../components/BaseIcon";
export default {
    name: "Home",
    components: {BaseIcon},
    data() {
        return {
            menuItems: [
                {
                    label: 'Retail',
                    icon: 'shoppingCart',
                    children: [
                        {
                            label: 'Retail chains',
                            listRoute: '/retail_chains',
                            createRoute: '/retail_chains/create',
                        },
                        {
                            label: 'Stores',
                            listRoute: '/stores',
                            createRoute: '/stores/create',
                        },
                        {
                            label: 'Field visits',
                            listRoute: '/field_visits',
                            createRoute: '/field_visits/create',
                        },
                    ],
                },
                {
                    label: 'Management',
                    icon: 'userGroup',
                    children: [
                        {
                            label: 'Users',
                            listRoute: '/users',
                            createRoute: '/users/create',
                        },
                    ],
                },
            ],
        };
    },
};
</script>
