<template>
    <div class="text-sm mb-4 text-semilight-text">
        <span v-for="(breadcrumb, i) in breadcrumbRoutes" :key="i">
            <router-link :to="breadcrumb" class="hover:text-liq-a">{{ breadcrumb.meta.breadcrumbName }}</router-link>
            <span class="mx-2">&gt;</span>
        </span>
        <span class="font-semibold text-bold-text">{{ currentRouteName }}</span>
    </div>
</template>

<script>
export default {
    name: "Breadcrumbs",
    props: {
        overrideRouteName: {
            type: String,
            default: null,
        },
    },
    computed: {
        breadcrumbRoutes() {
            const currentRoute = this.$route;

            const routeParents = this.findParentsOfRoute(currentRoute);

            return routeParents.reverse();
        },
        currentRouteName() {
            if (this.overrideRouteName) {
                return this.overrideRouteName;
            }

            const currentRoute = this.$route;
            if (Object.prototype.hasOwnProperty.call(currentRoute.meta, 'breadcrumbName')) {
                return currentRoute.meta.breadcrumbName;
            }

            return 'No route name';
        },
    },
    methods: {
        findParentsOfRoute(route) {
            let routeParents = [];

            if (!Object.prototype.hasOwnProperty.call(route.meta, 'parentRoute')) {
                return [];
            }

            const parentRoute = this.$router.resolve({
                name: route.meta.parentRoute,
            });

            if (!parentRoute.route) {
                return routeParents;
            }

            routeParents.push(parentRoute.route);

            const ancestorRoutes = this.findParentsOfRoute(parentRoute.route);
            routeParents = routeParents.concat(ancestorRoutes);

            return routeParents;
        },
    },
}
</script>
