const MAYONAISE_450_ML = {
        'key': '450_ml_mayonaise',
        'label': '450ml Mayonaise',
    },
    KETCHUP_450_ML = {
        'key': '450_ml_ketchup',
        'label': '450ml Ketchup',
    },
    CURRY_KETCHUP_450_ML = {
        'key': '450_ml_curry_ketchup',
        'label': '450ml Curry Ketchup',
    },
    ANDALOUSE_450_ML = {
        'key': '450_ml_andalouse',
        'label': '450ml Andalouse',
    },
    COCKTAIL_450_ML = {
        'key': '450_ml_cocktail',
        'label': '450ml Cocktail',
    },
    SAMOURAI_450_ML = {
        'key': '450_ml_samourai',
        'label': '450ml Samourai',
    };

function all() {
    return [
        MAYONAISE_450_ML,
        KETCHUP_450_ML,
        CURRY_KETCHUP_450_ML,
        ANDALOUSE_450_ML,
        COCKTAIL_450_ML,
        SAMOURAI_450_ML,
    ];
}

function getLabelForKey(skuTypeKey) {
    const skuType = this.all().find(skuType => skuTypeKey === skuType.key);

    return skuType.label;
}

export default { all, getLabelForKey };
