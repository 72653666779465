<template>
    <div class="flex flex-row h-full flex-nowrap items-stretch">
        <sidebar @toggled-expanded-state="toggledSidebarExpandedState" />

        <main class="flex-1 flex flex-col overflow-x-auto">
            <header class="flex-shrink-0 flex flex-row flex-no-wrap h-12 md:h-16 bg-white items-center justify-between text-jaspers-black">
                <router-link :to="{ name: 'home' }">
                    <img src="./../assets/img/logo-jaspers-black.svg" alt="Jasper’s Sauzen" class="h-5 sm:h-7 md:h-9 inline-block ml-4 md:ml-8 mr-4 md:mr-8">
                </router-link>

                <span class="text-sm md:text-base">
                    <span
                        class="hidden"
                        :class="{
                            'sm:block': true === expandedSidebar,
                            'xs:block': false === expandedSidebar,
                        }"
                    >
                        Field Sales <span class="hidden sm:inline-block">Portal</span>
                    </span>
                    <span v-if="appEnvironment !== 'production'" class="text-xs uppercase hidden sm:block">
                        ({{ appEnvironment }})
                        <span v-if="appUser">- {{ appUser.roles.join(',') }}</span>
                    </span>
                </span>

                <sync-popover class="ml-auto" />

                <t-dropdown v-if="isAuthenticated">
                    <div
                        slot="trigger"
                        slot-scope="{
                            mousedownHandler,
                            focusHandler,
                            blurHandler,
                            keydownHandler,
                            isShown,
                        }"
                        class="h-full"
                    >
                        <button
                            class="h-full border-solid border-l border-edge-liq-a focus:outline-none hover:bg-liq-a-alt"
                            :class="{ 'bg-liq-a-alt': isShown }"
                            aria-label="User menu"
                            aria-haspopup="true"
                            @mousedown="mousedownHandler"
                            @focus="focusHandler"
                            @blur="blurHandler"
                            @keydown="keydownHandler"
                        >
                            <span class="flex flex-row items-center mx-2 md:mx-4 sm:bg-liq-b rounded-full">
                                <span class="relative block w-6 md:w-8 h-6 md:h-8">
                                    <base-icon name="user" class="rounded-full text-liq-b" />
                                    <svg
                                        class="w-4 h-4 absolute -right-0.5 -bottom-0.5 border-2 border-liq-a rounded-full"
                                        :class="{'text-status-online': isOnline, 'text-status-offline': !isOnline}"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                    >
                                        <circle cx="10" cy="10" r="10" fill="currentColor" />
                                    </svg>
                                </span>

                                <span v-if="appUser" class="ml-1 md:ml-2 mr-2 md:mr-3 text-liq-c whitespace-nowrap text-sm md:text-base hidden sm:block">
                                    Hi, <span class="capitalize">{{ appUser.username }}!</span>
                                </span>
                            </span>
                        </button>
                    </div>
                    <div
                        slot-scope="{ blurHandler }"
                        class="py-2 rounded-md shadow-xs"
                    >
                        <router-link
                            :to="{
                                name: 'user_edit',
                                params: {
                                    userId: appUserId
                                }
                            }"
                            class="block w-full text-left px-4 py-2 text-sm leading-5 text-dropdown-text hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                        >
                            User settings
                        </router-link>
                        <button
                            class="block w-full text-left px-4 py-2 text-sm leading-5 text-dropdown-text hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"
                            role="menuitem"
                            @click="logout"
                            @blur="blurHandler"
                        >
                            Sign out
                        </button>
                    </div>
                </t-dropdown>
                <span v-else class="border-l border-edge-liq-a h-full flex flex-row items-center px-2 whitespace-nowrap">
                    <span>
                        Not logged in
                    </span>
                </span>
            </header>

            <div class="flex-auto p-4 md:p-8 overflow-y-auto">
                <router-view />
            </div>
        </main>

        <notification-container />
        <v-offline @detected-condition="updateOnlineStatus" />

        <authentication-preloader v-if="false === isAuthenticated" :auth-error="authError" />
    </div>
</template>

<script>

import VOffline from 'v-offline';
import Vue from "vue";
import './util/dayJs';
import ConstantsPlugin from "./util/constants";
import NotificationPlugin from "./util/notificationPlugin";
import UUID from "vue-uuid";
import Notifications from "vt-notifications";
import NotificationContainer from "./components/NotificationContainer";
import SyncPopover from "./components/SyncPopover";
import VueTables from 'vue-tables-2';
import apiClientMiddleware from "./util/apiClientMiddleware";
import Authenticator from "./security/authenticator";
import Vuelidate from 'vuelidate';
import VueTailwind from 'vue-tailwind';
import vueTailwindSettings from "./components/ui-components/vueTailwindTheme";
import Sidebar from "./components/navigation/Sidebar";
import VueCookies from 'vue-cookies';
import VtTableRow from "./components/vue-table-overrides/VtTableRow";
import VtTableHeading from "./components/vue-table-overrides/VtTableHeading";
import VtHeadingsRow from "./components/vue-table-overrides/VtHeadingsRow";
import VtDataTable from "./components/vue-table-overrides/VtDataTable";
import VtGenericFilter from "./components/vue-table-overrides/VtGenericFilter";
import VtPerPageSelector from "./components/vue-table-overrides/VtPerPageSelector";
import VtNoResultsRow from "./components/vue-table-overrides/VtNoResultsRow";
import MyPagination from "./components/vue-table-overrides/MyPagination";
import AuthenticationPreloader from "./components/AuthenticationPreloader";
import BaseIcon from "./components/BaseIcon";
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

Vue.use(ConstantsPlugin);
Vue.use(UUID);
Vue.use(Notifications);
Vue.use(NotificationPlugin);
Vue.use(
    VueTables.ClientTable,
    {
        preserveState: true,

        sortIcon: {
            base: 'inline-block w-4 h-4 ml-1 flex-none',
            up: 'heroicon-chevron-up',
            down: 'heroicon-chevron-down',
            is: 'heroicon-selector'
        },

        texts: {
            limit: 'items per page',
            filterPlaceholder: 'Search',
        },
    },
    false,
    require('./components/vue-table-overrides/custom-tailwind'),
    {
        dataTable: VtDataTable,
        genericFilter: VtGenericFilter,
        perPageSelector: VtPerPageSelector,
        tableRow: VtTableRow,
        headingsRow: VtHeadingsRow,
        tableHeading: VtTableHeading,
        noResultsRow: VtNoResultsRow,
        pagination: MyPagination,
    }
);
Vue.use(Vuelidate);
Vue.use(VueTailwind, vueTailwindSettings);
Vue.use(VueCookies);
Vue.$cookies.config('30d');
Vue.use(Viewer);

export default {
    name: "App",
    components: {
        BaseIcon,
        AuthenticationPreloader,
        Sidebar,
        SyncPopover,
        NotificationContainer,
        VOffline
    },
    data() {
        return {
            appEnvironment: process.env.NODE_ENV,
            authError: null,
            expandedSidebar: null,
        }
    },
    computed: {
        isAuthenticated() {
            return this.$store.getters['security/isAuthenticated'];
        },
        appUser() {
            return this.$store.getters['security/appUser'];
        },
        appUserId() {
            return this.$store.getters['security/appUserId'];
        },
        isOnline() {
            return this.$store.getters["sync/isOnline"];
        },
    },
    async created() {
        apiClientMiddleware.addInterceptors();

        if ('authorization_callback' === this.$route.name) {
            try {
                await Authenticator.handleLoginResponse();
            }
            catch (exception) {
                this.authError = exception.message;
                return;
            }
        }

        await this.$store.dispatch("security/fetchInitialState");
        if (false === this.isAuthenticated) {
            Authenticator.redirectToLogin();
            return;
        }

        this.$store.dispatch("sync/fetchInitialState")
            .then(() => this.$store.dispatch("user/fetchAllCached"))
            .then(() => this.$store.dispatch("language/fetchAllCached"))
            .then(() => this.$store.dispatch("country/fetchAllCached"))
            .then(() => this.$store.dispatch("retailChain/fetchAllCached"))
            .then(() => this.$store.dispatch("store/fetchAllCached"))
            .then(() => this.$store.dispatch("fieldVisit/fetchAllCached"))

            .then(() => this.$store.dispatch("sync/syncData", {
                silenceNotifications: true,
            }))

            .catch(exception => {
                this.showErrorNotification("Error loading data", `An error occurred while loading data: "${exception.message}"`);
            })
        ;

        this.schedulePeriodicSync();
    },
    methods: {
        updateOnlineStatus(event) {
            this.$store.dispatch("sync/updateNetworkStatus", {
                isOnline: event,
            });
        },
        logout() {
            Authenticator.logout();
        },
        schedulePeriodicSync() { // To periodically sync the data in the app
            setInterval(() => {
                if ('production' !== this.appEnvironment) {
                    console.log('Cancelled! (DEV MODE) - Periodic sync timeout reached, triggering automatic content update');
                    return;
                }
                console.log('Periodic sync timeout reached, triggering automatic content update');
                this.$store.dispatch("sync/syncData");
            }, 300000); // = every 5 minutes
        },
        toggledSidebarExpandedState(expandedSidebar) {
            this.expandedSidebar = expandedSidebar;
        },
    },
}
</script>
