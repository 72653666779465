const CHECK_PLANOGRAM = {
        'key': 'planogram',
        'label': 'Planogram',
        'help_text': 'Are the products visible on the shelf according to the predetermined plan?',
    },
    CHECK_TRAYS = {
        'key': 'trays',
        'label': 'Display placed',
        'help_text': 'Are the products on the Display or on the shelve?',
    };

function all() {
    return [
        CHECK_PLANOGRAM,
        CHECK_TRAYS,
    ];
}

function getByKey(brandValueCheckTypeKey) {
    return this.all().find(brandValueCheckType => brandValueCheckTypeKey === brandValueCheckType.key);
}

export default { all, getByKey };
