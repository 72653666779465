import {uuid} from "vue-uuid";
import SyncStatusEnum from "../store/sync/syncStatusEnum";
import dayjs from "dayjs";
import ChangeSetComputer from "../util/changeSetComputer";
import AddressModel from "./address";
import store from "../store";

export default class Store {
    constructor(
        iri,
        id,
        name,
        retailChainIri,
        languageIri,
        address,
        createdAt,
        updatedAt,
        syncedAt,
        syncStatus,
        modifiedFields
    ) {
        this.iri = iri;
        this.id = id;
        this.name = name;
        this.retailChainIri = retailChainIri;
        this.languageIri = languageIri;
        this.address = address;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.syncedAt = syncedAt;

        this.syncStatus = syncStatus;
        this.modifiedFields = modifiedFields;
    }

    get nameWithAddress() { // For search purposes in the rich select
        return `${this.name} ${this.address.toString()}`;
    }

    static transformFromAPI(storeData) {
        return new this(
            storeData['@id'],
            storeData.id,
            storeData.name,
            storeData.retailChain,
            storeData.language,
            AddressModel.transformFromAPINested(storeData.address),
            storeData.createdAt,
            storeData.updatedAt,
            storeData.syncedAt,
            null === storeData.deletedAt ? SyncStatusEnum.IN_SYNC : SyncStatusEnum.IN_SYNC_DELETED,
            [],
        );
    }

    transformToApi(isNewItem) {
        const fieldTransformations = {
            id: isNewItem ? {
                outputKey: 'id',
                outputValue: this.id
            } : null,
            name: {
                outputKey: 'name',
                outputValue: this.name,
                options: {
                    filterIfNotModified: true,
                },
            },
            retailChainIri: {
                outputKey: 'retailChain',
                outputValue: '' === this.retailChainIri ? null : this.retailChainIri,
                options: {
                    filterIfNotModified: true,
                },
            },
            languageIri: {
                outputKey: 'language',
                outputValue: this.languageIri,
                options: {
                    filterIfNotModified: true,
                },
            },
            address: {
                outputKey: 'address',
                outputValue: {
                    iri: isNewItem ? null : {
                        outputKey: '@id', // adding @id makes API platform edit the original record instead of creating a new one
                        outputValue: this.address.iri,
                    },
                    city: {
                        outputKey: 'city',
                        outputValue: this.address.city,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    number: {
                        outputKey: 'number',
                        outputValue: this.address.number,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    postalCode: {
                        outputKey: 'postalCode',
                        outputValue: this.address.postalCode,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    street: {
                        outputKey: 'street',
                        outputValue: this.address.street,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                    countryIri: {
                        outputKey: 'country',
                        outputValue: this.address.countryIri,
                        options: {
                            filterIfNotModified: true,
                        },
                    },
                },
            },

            createdAt: isNewItem ? {
                outputKey: 'createdAt',
                outputValue: dayjs(this.createdAt).toISOString()
            } : null,
            updatedAt: {
                outputKey: 'updatedAt',
                outputValue: dayjs(this.updatedAt).toISOString()
            },
        };

        return ChangeSetComputer.filterFieldsByChangeSet(
            fieldTransformations,
            this.modifiedFields,
            isNewItem
        );
    }

    static transformFromLocalCache(storeData) {
        return new this(
            storeData.iri,
            storeData.id,
            storeData.name,
            storeData.retailChainIri,
            storeData.languageIri,
            AddressModel.transformFromLocalCacheNested(storeData.address),
            storeData.createdAt,
            storeData.updatedAt,
            storeData.syncedAt,
            storeData.syncStatus,
            storeData.modifiedFields,
        );
    }

    static createNew(formData) {
        const id = uuid.v4();
        var now = dayjs();

        return new this(
            this.buildIriFromId(id),
            id,
            formData.name,
            formData.retailChainIri,
            formData.languageIri,
            AddressModel.createNewNested(formData.address),
            now.format(),
            now.format(),
            null,
            SyncStatusEnum.CREATED_LOCALLY,
            [],
        );
    }

    commitUpdate(formData) {
        ChangeSetComputer.computeChangeSet(this, formData);

        this.name = formData.name;
        this.retailChainIri = formData.retailChainIri;
        this.languageIri = formData.languageIri;

        this.address.commitUpdateNested(formData.address);

        var now = dayjs();

        if (null === this.createdAt) {
            this.createdAt = now.format();
        }
        this.updatedAt = now.format();
        if (SyncStatusEnum.CREATED_LOCALLY !== this.syncStatus) {
            this.syncStatus = SyncStatusEnum.UPDATED_LOCALLY;
        }
    }

    toString() {
        const retailChain = this.getRetailChain();

        return `${this.name}${retailChain ? (' (' + retailChain.toString() + ')') : ''}`;
    }

    static buildIriFromId(id) {
        return `/stores/${id}`
    }

    getRetailChain() {
        return store.getters["retailChain/retailChainByIri"](this.retailChainIri);
    }

    getLanguage() {
        return store.getters["language/languageByIri"](this.languageIri);
    }

    static excelExportMapping(record) {
        const mapping = {
            'name': record.name,
            'retailChain': record.getRetailChain() ? record.getRetailChain().toString() : '',
            'language': record.getLanguage() ? record.getLanguage().toString() : '',
            'city': record.address.city,
            'number': record.address.number,
            'postalCode': record.address.postalCode,
            'street': record.address.street,
            'country': record.address.getCountry() ? record.address.getCountry().name : '',
        };

        return mapping;
    }
}
