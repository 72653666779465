<template>
    <div>
        <div class="float-left w-1/2 sm:w-1/3 clear-left mt-2">
            {{ label }}
        </div>
        <div class="float-left w-1/2 sm:w-2/3 text-bold-text font-semibold mt-2">
            <slot />
        </div>
        <div class="clear-both" />
    </div>
</template>

<script>
export default {
    name: "DetailItem",
    props: {
        label: {
            type: String,
            default: null,
        },
    },
}
</script>
