<template>
    <div>
        <breadcrumbs :override-route-name="visitingStore ? ('Field visit for ' + visitingStore.toString()) : null" />

        <h1>
            <span v-if="!isEditForm">Create</span>
            <span v-else>Edit</span>
            field visit
            <entity-sync-status v-if="fieldVisit" :sync-status="fieldVisit.syncStatus" />
        </h1>

        <div v-if="isFormPopulated">
            <form>
                <group-label>General</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-rich-select
                            label="Store"
                            :field="$v.formData.storeIri"
                            :items="stores"
                            value-attribute="iri"
                            text-attribute="nameWithAddress"
                            :disabled="isEditForm"
                        >
                            <template
                                slot="label"
                                slot-scope="{ className, option }"
                            >
                                {{ option.raw.name }}
                            </template>
                            <template
                                slot="option"
                                slot-scope="{ index, isHighlighted, isSelected, className, option }"
                            >
                                <div class="flex flex-col px-3 py-2">
                                    <span class="block">{{ option.raw.name }}</span>
                                    <span class="text-xs leading-tight">{{ option.raw.address.toString() }}</span>
                                </div>
                            </template>
                        </validated-rich-select>
                    </div>
                </div>

                <div v-if="visitingStore">
                    <definition-list-card
                        label="Store details"
                        :definition-list="[
                            {
                                slot_name: 'name',
                                label: 'Name',
                            },
                            {
                                slot_name: 'language',
                                label: 'Language',
                            },
                            {
                                slot_name: 'address',
                                label: 'Address',
                            },
                        ]"
                    >
                        <template slot="name">
                            {{ visitingStore.toString() }}
                        </template>
                        <template slot="language">
                            {{ languageByIri(visitingStore.languageIri).name }}
                        </template>
                        <template slot="address">
                            {{ visitingStore.address }}
                        </template>
                    </definition-list-card>
                </div>

                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-date-picker label="Visit date" :field="$v.formData.visitedDate" />
                    </div>
                    <div>
                        <validated-rich-select
                            label="New customer status"
                            :field="$v.formData.newCustomerStatus"
                            :items="newCustomerStatuses"
                            value-attribute="key"
                            text-attribute="label"
                            :hide-search-box="true"
                        />
                    </div>
                </div>

                <div class="grid grid-cols-3 gap-x-4">
                    <group-label>
                        Actual SKU amounts
                        <help-popover>
                            Current amount of SKU's in shelves
                        </help-popover>
                    </group-label>

                    <group-label>
                        New SKU
                        <help-popover>
                            Amount of new SKU's negotiated
                        </help-popover>
                    </group-label>

                    <group-label>
                        Orders
                        <help-popover>
                            Amount of colli's ordered
                        </help-popover>
                    </group-label>

                    <template v-for="(v, index) in $v.formData.skuSurveys.$each.$iter">
                        <validated-checkbox :key="'sku_survey_sku_amount_' + index" :label="skuTypeLabelByKey(v.$model.type)" :field="v.skuAmount" />
                        <validated-checkbox :key="'sku_survey_new_sku_amount_' + index" :label="skuTypeLabelByKey(v.$model.type)" :field="v.newSkuAmount" />
                        <validated-input :key="'sku_survey_colli_ordered_' + index" :label="'Colli ' + skuTypeLabelByKey(v.$model.type).toLowerCase()" :field="v.colliOrdered" type="number" />
                    </template>
                </div>

                <group-label>Brand value checks</group-label>
                <table class="table-fixed border-collapse w-full">
                    <thead class="hidden sm:table-header-group">
                        <tr class="bg-backdrop-primary">
                            <th />
                            <th class="text-center">
                                OK on arrival
                            </th>
                            <th class="text-center">
                                Agreement made and resolved during visit
                            </th>
                            <th class="text-center">
                                Comment
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(v, index) in $v.formData.brandValueChecks.$each.$iter"
                            :key="'brand_value_check_' + index"
                            :set="brandValueCheck = brandValueCheckByKey(v.$model.type)"
                            class="block sm:table-row mb-4 sm:mb-0"
                        >
                            <td class="text-left sm:text-center block sm:table-cell">
                                {{ brandValueCheck.label }}
                                <help-popover>
                                    {{ brandValueCheck.help_text }}
                                </help-popover>

                                <div v-if="isBrandValueCheckDisabled()">
                                    Not allowed in <span v-if="isDelhaizeVisit">Delhaize</span> stores
                                </div>
                            </td>
                            <td class="text-left sm:text-center block sm:table-cell">
                                <span class="inline sm:hidden">OK on arrival:</span>
                                <validated-checkbox :field="v.okOnArrival" :disabled="isBrandValueCheckDisabled(v.$model.type)" />
                            </td>
                            <td class="text-left sm:text-center block sm:table-cell">
                                <span class="inline sm:hidden">Agreement made and resolved during visit:</span>
                                <validated-checkbox :field="v.fixedDuringVisit" :disabled="true === v.okOnArrival.$model || isBrandValueCheckDisabled(v.$model.type)" />
                            </td>
                            <td class="text-left sm:text-center block sm:table-cell">
                                <span class="inline sm:hidden">Comment:</span>
                                <validated-textarea :field="v.fixedDuringVisitComment" :disabled="true === v.okOnArrival.$model || isBrandValueCheckDisabled(v.$model.type)" />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <div class="float-right">
                            <help-popover align="right">
                                How many coupons are on the display when arriving at the store
                            </help-popover>
                        </div>

                        <validated-input label="Amount of coupons available on arrival" :field="$v.formData.numberOfCouponsOnDisplay" type="number" />
                    </div>
                    <div>
                        <div class="float-right">
                            <help-popover align="right">
                                How many total coupons are on the display when you have refilled them when you left the store
                            </help-popover>
                        </div>

                        <validated-input label="Amount of coupons placed on the Display" :field="$v.formData.numberOfCouponsOnDisplayAfterVisit" type="number" />
                    </div>
                </div>

                <validated-textarea label="Comments" :field="$v.formData.comment" />

                <group-label>Attach media</group-label>
                <div>
                    <div class="float-left w-full sm:w-1/3">
                        <validated-file-input label="Photo(s) before visit" :field="$v.photoCountBeforeVisit" @uploadSubmitted="handleFileUploadBeforeVisit" />
                    </div>
                    <t-card class="float-left w-full sm:w-2/3">
                        <h2 class="border-b border-edge-primary pb-4 mb-4">
                            Photo(s) of the Jasper’s Sauzen shelf when you arrive
                        </h2>
                        <div class="mb-4">
                            Number of photos: {{ $v.photoCountBeforeVisit.$model }}
                        </div>
                        <viewer :images="fieldVisitPhotosBeforeVisit" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
                            <div v-for="(photo, index) in fieldVisitPhotosBeforeVisit" :key="'field_visit_photo_' + index" class="relative">
                                <div
                                    v-if="!photo.uploaded"
                                    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-liq-a bg-backdrop-primary bg-opacity-75 pointer-events-none"
                                >
                                    <base-icon name="cloudUpload" class="w-16 h-16" />
                                </div>
                                <img :src="photo.src" class="w-full cursor-pointer">
                            </div>
                        </viewer>
                    </t-card>
                    <div class="clear-both" />
                </div>
                <div>
                    <div class="float-left w-full sm:w-1/3">
                        <validated-file-input label="Photo(s) after visit" :field="$v.photoCountAfterVisit" @uploadSubmitted="handleFileUploadAfterVisit" />
                    </div>
                    <t-card class="float-left w-full sm:w-2/3">
                        <h2 class="border-b border-edge-primary pb-4 mb-4">
                            Photo(s) of the Jasper’s Sauzen shelf when you leave
                        </h2>
                        <div class="mb-4">
                            Number of photos: {{ $v.photoCountAfterVisit.$model }}
                        </div>
                        <viewer :images="fieldVisitPhotosAfterVisit" class="grid grid-cols-2 sm:grid-cols-4 gap-4">
                            <div v-for="(photo, index) in fieldVisitPhotosAfterVisit" :key="'field_visit_photo_' + index" class="relative">
                                <div
                                    v-if="!photo.uploaded"
                                    class="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center text-liq-a bg-backdrop-primary bg-opacity-75 pointer-events-none"
                                >
                                    <base-icon name="cloudUpload" class="w-16 h-16" />
                                </div>
                                <img :src="photo.src" class="w-full cursor-pointer">
                            </div>
                        </viewer>
                    </t-card>
                    <div class="clear-both" />
                </div>

                <group-label>Store manager/category manager</group-label>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input label="Name" :field="$v.formData.managerName" />
                    </div>
                </div>
                <div class="grid sm:grid-cols-2 gap-x-4">
                    <div>
                        <validated-input label="Motivation" :field="$v.formData.freezerManagerMotivation" />
                    </div>
                    <div>
                        <validated-checkbox label="Hastily person?" :field="$v.formData.freezerManagerIsHastily" />
                    </div>
                </div>

                <button
                    type="button"
                    class="btn-liq-primary block sm:inline-block"
                    @click="submit()"
                >
                    <span v-if="!isEditForm">Create</span>
                    <span v-else>Edit</span>
                    field visit
                </button>

                <router-link
                    :to="{ name: 'field_visit_overview' }"
                    class="btn-liq-default inline-block ml-0 sm:ml-2 mt-2 sm:mt-0"
                >
                    Back
                </router-link>
            </form>
        </div>
        <div v-else>
            Loading data...
        </div>
    </div>
</template>

<script>

import EntitySyncStatus from "../../components/EntitySyncStatus";
import {required, requiredIf, minValue, minLength, maxLength, integer} from 'vuelidate/lib/validators'
import { req, ref, withParams } from 'vuelidate/lib/validators/common'
import FormSubmitStatus from "../../util/formSubmitStatus";
import ValidatedRichSelect from "../../components/ui-components/form-elements/ValidatedRichSelect";
import GroupLabel from "../../components/ui-components/form-elements/GroupLabel";
import ValidatedDatePicker from "../../components/ui-components/form-elements/ValidatedDatePicker";
import NewCustomerStatus from "../../util/newCustomerStatus";
import SkuType from "../../util/skuType";
import BrandValueCheckType from "../../util/brandValueCheckType";
import ValidatedInput from "../../components/ui-components/form-elements/ValidatedInput";
import ValidatedCheckbox from "../../components/ui-components/form-elements/ValidatedCheckbox";
import dayjs from "dayjs";
import ValidatedFileInput from "../../components/ui-components/form-elements/ValidatedFileInput";
import ValidatedTextarea from "../../components/ui-components/form-elements/ValidatedTextarea";
import DefinitionListCard from "../../components/ui-components/form-elements/DefinitionListCard";
import HelpPopover from "../../components/ui-components/form-elements/HelpPopover";
import BaseIcon from "../../components/BaseIcon";
import StoreModel from "../../model/store";
import Breadcrumbs from "../../components/Breadcrumbs";

// A combination of the requiredIf & sameAs validator
const sameAsIf = (prop, equalTo) =>
    withParams({ type: 'sameAsIf', prop, equalTo }, function(value, parentVm) {
        return ref(prop, this, parentVm) ? (req(value) && value === ref(equalTo, this, parentVm)) : true
    })
;

export default {
    name: "FieldVisitForm",
    components: {
        Breadcrumbs,
        BaseIcon,
        HelpPopover,
        DefinitionListCard,
        ValidatedTextarea,
        ValidatedFileInput,
        ValidatedCheckbox,
        ValidatedInput, ValidatedDatePicker, GroupLabel, ValidatedRichSelect, EntitySyncStatus
    },
    props: {
        fieldVisitId: {
            type: [String],
            default: function () {
                return null;
            }
        },
        presetStoreId: {
            type: [String],
            default: function () {
                return null;
            }
        },
    },
    data() {
        const skuTypes = SkuType.all();
        const brandValueCheckTypes = BrandValueCheckType.all();
        const now = dayjs();

        return {
            isEditForm: false,
            isFormPopulated: false,
            submitStatus: null,

            newCustomerStatuses: NewCustomerStatus.all(),
            skuTypes: skuTypes,
            brandValueCheckTypes: brandValueCheckTypes,
            apiUrl: process.env.VUE_APP_API_URL,

            formData: {
                storeIri: "",
                visitedDate: now.format(),
                newCustomerStatus: NewCustomerStatus.defaultStatus().key,
                skuSurveys: skuTypes.map(skuType => ({
                    type: skuType.key,
                    skuAmount: false,
                    newSkuAmount: false,
                    colliOrdered: 0,
                })),
                brandValueChecks: brandValueCheckTypes.map(brandValueCheckType => ({
                    type: brandValueCheckType.key,
                    okOnArrival: false,
                    fixedDuringVisit: false,
                    fixedDuringVisitComment: "",
                })),
                numberOfCouponsOnDisplay: 0,
                numberOfCouponsOnDisplayAfterVisit: 0,
                comment: "",

                queuedMediaUploadsBeforeVisit: [],
                queuedMediaUploadsAfterVisit: [],

                freezerManagerMotivation: "",
                freezerManagerIsHastily: false,
                managerName: "",
            },

            createdAt: null,
            updatedAt: null,
            syncedAt: null,
            syncStatus: "",
        };
    },
    validations: {
        formData: {
            storeIri: {
                required,
            },
            visitedDate: {
                required,
            },
            newCustomerStatus: {
                required,
            },
            skuSurveys: {
                $each: { // order of the fields is important! Follow the same order as the object gets loaded from cache.
                    skuAmount: {
                        required,
                    },
                    newSkuAmount: {
                        required,
                    },
                    colliOrdered: {
                        required,
                        integer,
                        minValue: minValue(0),
                    },
                },
            },
            brandValueChecks: {
                $each: { // order of the fields is important! Follow the same order as the object gets loaded from cache.
                    okOnArrival: {
                        required,
                    },
                    fixedDuringVisit: {
                        sameAsIf: sameAsIf( // Explanation: Should be required AND checked IF (not OK on arrival AND brand value check is not disabled)
                            function(brandValueCheck) {
                                return false === brandValueCheck.okOnArrival && !this.isBrandValueCheckDisabled(brandValueCheck.type);
                            },
                            function () {
                                return true; // = checked
                            }
                        ),
                    },
                    fixedDuringVisitComment: {
                        requiredIf: requiredIf(function(brandValueCheck) {
                            return false === brandValueCheck.okOnArrival && !this.isBrandValueCheckDisabled(brandValueCheck.type);
                        }),
                    },
                },
            },
            numberOfCouponsOnDisplay: {
                required,
                integer,
                minValue: minValue(0),
            },
            numberOfCouponsOnDisplayAfterVisit: {
                required,
                integer,
                minValue: minValue(0),
            },
            comment: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(400),
            },
            freezerManagerMotivation: {
                required,
                minLength: minLength(2),
            },
            freezerManagerIsHastily: {
            },
            managerName: {
                required,
                minLength: minLength(2),
            },
        },
        photoCountBeforeVisit: {
            required,
            minValue: minValue(1),
        },
        photoCountAfterVisit: {
            required,
            minValue: minValue(1),
        },
    },
    computed: {
        fieldVisits() {
            return this.$store.getters["fieldVisit/fieldVisits"];
        },
        stores() {
            return this.$store.getters["store/stores"];
        },
        fieldVisit() {
            return this.$store.getters["fieldVisit/fieldVisitById"](this.fieldVisitId);
        },
        visitingStore() {
            return this.$store.getters["store/storeByIri"](this.formData.storeIri);
        },
        isDelhaizeVisit() {
            const store = this.visitingStore;
            if (null === store || undefined === store) {
                return false;
            }

            const retailChain = store.getRetailChain();
            if (null === retailChain || undefined === retailChain) {
                return false;
            }

            return 'Delhaize' === retailChain.name;
        },
        photoCountBeforeVisit() {
            return this.fieldVisitPhotosBeforeVisit.length;
        },
        photoCountAfterVisit() {
            return this.fieldVisitPhotosAfterVisit.length;
        },
        fieldVisitPhotosBeforeVisit() {
            const photos = this.formData.queuedMediaUploadsBeforeVisit.map(queuedMediaUpload => {
                return {
                    src: queuedMediaUpload.fileBase64,
                    uploaded: false,
                };
            });

            if (!this.fieldVisit) {
                return photos;
            }

            return photos.concat(
                this.fieldVisit.photosBeforeVisit.map(uploadedPhoto => {
                    return {
                        src: this.apiUrl + uploadedPhoto.contentUrl,
                        uploaded: true,
                    };
                })
            );
        },
        fieldVisitPhotosAfterVisit() {
            const photos = this.formData.queuedMediaUploadsAfterVisit.map(queuedMediaUpload => {
                return {
                    src: queuedMediaUpload.fileBase64,
                    uploaded: false,
                };
            });

            if (!this.fieldVisit) {
                return photos;
            }

            return photos.concat(
                this.fieldVisit.photosAfterVisit.map(uploadedPhoto => {
                    return {
                        src: this.apiUrl + uploadedPhoto.contentUrl,
                        uploaded: true,
                    };
                })
            );
        },
    },
    watch: {
        fieldVisits: function() {
            // This watcher triggers when the field visits have finished loading (once per app load)
            this.populateForm();
        },
    },
    created() {
        if (null !== this.fieldVisitId) {
            this.isEditForm = true;
        }
    },
    mounted() {
        // This event triggers everytime the form opens, but the first time (on reload) a store may not yet be available
        this.populateForm();
    },
    methods: {
        populateForm() {
            if (this.isFormPopulated) {
                return;
            }

            if (!this.fieldVisit && this.isEditForm) {
                return;
            }

            if (this.isEditForm) {
                this.formData.storeIri = this.fieldVisit.storeIri;
                this.formData.visitedDate = this.fieldVisit.visitedDate;
                this.formData.newCustomerStatus = this.fieldVisit.newCustomerStatus;
                this.formData.skuSurveys = this.skuTypes.map(skuType => {
                    if (!this.fieldVisit.skuSurveys) {
                        this.fieldVisit.skuSurveys = [];
                    }

                    let skuSurvey = this.fieldVisit.skuSurveys.find(skuSurvey => skuType.key === skuSurvey.type);

                    if (!skuSurvey) {
                        return {
                            type: skuType.key,
                            skuAmount: false,
                            newSkuAmount: false,
                            colliOrdered: 0,
                        };
                    }

                    return {
                        type: skuType.key,
                        skuAmount: skuSurvey.skuAmount,
                        newSkuAmount: skuSurvey.newSkuAmount,
                        colliOrdered: skuSurvey.colliOrdered,
                    };
                });
                this.formData.brandValueChecks = this.brandValueCheckTypes.map(brandValueCheckType => {
                    if (!this.fieldVisit.brandValueChecks) {
                        this.fieldVisit.brandValueChecks = [];
                    }

                    let brandValueCheck = this.fieldVisit.brandValueChecks.find(brandValueCheck => brandValueCheckType.key === brandValueCheck.type);

                    if (!brandValueCheck) {
                        return {
                            type: brandValueCheckType.key,
                            okOnArrival: false,
                            fixedDuringVisit: false,
                            fixedDuringVisitComment: "",
                        };
                    }

                    return {
                        type: brandValueCheckType.key,
                        okOnArrival: brandValueCheck.okOnArrival,
                        fixedDuringVisit: brandValueCheck.fixedDuringVisit,
                        fixedDuringVisitComment: brandValueCheck.fixedDuringVisitComment,
                    };
                });
                this.formData.numberOfCouponsOnDisplay = this.fieldVisit.numberOfCouponsOnDisplay;
                this.formData.numberOfCouponsOnDisplayAfterVisit = this.fieldVisit.numberOfCouponsOnDisplayAfterVisit;

                this.formData.comment = this.fieldVisit.comment;

                this.formData.queuedMediaUploadsBeforeVisit = this.fieldVisit.queuedMediaUploadsBeforeVisit;
                this.formData.queuedMediaUploadsAfterVisit = this.fieldVisit.queuedMediaUploadsAfterVisit;

                this.formData.freezerManagerMotivation = this.fieldVisit.freezerManagerMotivation;
                this.formData.freezerManagerIsHastily = this.fieldVisit.freezerManagerIsHastily;
                this.formData.managerName = this.fieldVisit.managerName;
            }
            else {
                if (this.presetStoreId) {
                    this.formData.storeIri = StoreModel.buildIriFromId(this.presetStoreId);
                }
            }

            this.isFormPopulated = true;
        },
        async handleFileUploadBeforeVisit(mediaUploads) {
            this.$v.photoCountBeforeVisit.$touch();

            if (undefined === this.formData.queuedMediaUploadsBeforeVisit) {
                this.formData.queuedMediaUploadsBeforeVisit = [];
            }

            this.formData.queuedMediaUploadsBeforeVisit = this.formData.queuedMediaUploadsBeforeVisit.concat(mediaUploads);
        },
        async handleFileUploadAfterVisit(mediaUploads) {
            this.$v.photoCountAfterVisit.$touch();

            if (undefined === this.formData.queuedMediaUploadsAfterVisit) {
                this.formData.queuedMediaUploadsAfterVisit = [];
            }

            this.formData.queuedMediaUploadsAfterVisit = this.formData.queuedMediaUploadsAfterVisit.concat(mediaUploads);
        },
        async submit() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.submitStatus = FormSubmitStatus.STATUS_ERROR;
                this.showErrorNotification("Validation error", "Whoops! Please correct any validation errors and try again.");

                return;
            }

            this.submitStatus = FormSubmitStatus.STATUS_SUBMITTING;

            try {
                if (this.isEditForm) {
                    await this.$store.dispatch(
                        "fieldVisit/update",
                        {
                            id: this.fieldVisitId,
                            ...this.formData,
                        }
                    );
                }
                else {
                    await this.$store.dispatch(
                        "fieldVisit/create",
                        this.formData
                    );
                }
            }
            catch (exception) {
                this.showErrorNotification("Save error", `An error occurred while saving: "${exception.message}"`);
            }

            this.showSuccessNotification("Success", `Field visit for store "${this.visitingStore ? this.visitingStore.toString() : this.formData.storeIri}" has been saved!`);

            await this.$store.dispatch("sync/syncData", {
                silenceNotifications: true,
            });

            this.$router.push({name: 'field_visit_overview'});
        },
        skuTypeLabelByKey(skuTypeKey) {
            return SkuType.getLabelForKey(skuTypeKey);
        },
        brandValueCheckByKey(brandValueCheckTypeKey) {
            return BrandValueCheckType.getByKey(brandValueCheckTypeKey);
        },
        languageByIri(languageIri) {
            return this.$store.getters["language/languageByIri"](languageIri);
        },
        isBrandValueCheckDisabled() {
            return this.isDelhaizeVisit;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.$v.$anyDirty) {
            next();
            return;
        }

        if (FormSubmitStatus.STATUS_SUBMITTING === this.submitStatus) {
            next();
            return;
        }

        this.$dialog
            .confirm({
                title: 'Leave without saving changes?',
                text: 'All changes will be lost!',
                icon: 'info',
                cancelButtonText: 'No, stay here!',
                okButtonText: 'Yes, leave',
            })
            .then((result) => {
                if (result && result.isOk) {
                    next();
                    return;
                }

                next(false);
            })
        ;
    },
};
</script>
