<template>
    <t-dropdown variant="wide">
        <div
            slot="trigger"
            slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
                isShown,
            }"
            class="h-full"
        >
            <button
                class="w-12 md:w-16 h-full border-solid border-l border-edge-liq-a focus:outline-none hover:bg-liq-a-alt"
                :class="{ 'bg-liq-a-alt': isShown }"
                aria-label="User menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
            >
                <base-icon
                    name="cloud"
                    class="w-6 md:w-8 h-6 md:h-8"
                />
                <base-icon
                    :name="(hasUnsyncedChanges) ? 'exclamationCircle' : (syncInProgress ? 'refreshAnimation' : 'check')"
                    :class="(hasUnsyncedChanges) ? 'text-liq-b' : (syncInProgress ? 'text-liq-b' : 'text-liq-b')"
                    class="w-4 h-4"
                />
            </button>
        </div>
        <div
            slot-scope="{ blurHandler }"
            class="p-4 text-sm leading-normal text-dropdown-text"
        >
            <div class="font-semibold">
                Cloud Sync
            </div>
            <div class="mt-3">
                <div v-if="hasUnsyncedChanges">
                    <span>
                        Some of your changes haven't been synced with the cloud yet. These will be synced the next time you connect.
                    </span>
                    <ul class="mt-3">
                        <li v-for="item in locallyCreatedItems" :key="item.id">
                            <base-icon name="plusCircle" class="text-liq-success w-4 h-4" />
                            You <span class="text-liq-success">added</span> <span class="font-semibold">{{ item }}</span> <i><span class="text-light-text">{{ $date(item.updatedAt).fromNow() }}</span></i>
                        </li>

                        <li v-for="item in locallyUpdatedItems" :key="item.id">
                            <base-icon name="pencilCircle" class="text-liq-d w-4 h-4" />
                            You <span class="text-liq-d">modified</span> <span class="font-semibold">{{ item }}</span>
                            (edited {{ userFriendlyModifiedItemFields(item.modifiedFields).join(', ') }})
                            <i><span class="text-light-text">{{ $date(item.updatedAt).fromNow() }}</span></i>
                        </li>

                        <li v-for="item in locallyDeletedItems" :key="item.id">
                            <base-icon name="minusCircle" class="text-liq-error w-4 h-4" />
                            You <span class="text-liq-error">deleted</span> <span class="font-semibold">{{ item }}</span> <i><span class="text-light-text">{{ $date(item.updatedAt).fromNow() }}</span></i>
                        </li>
                    </ul>
                    <div class="text-light-text mt-3">
                        ({{ locallyCreatedItems.length }} additions, {{ locallyUpdatedItems.length }} modifications, {{ locallyDeletedItems.length }} deletions)
                    </div>
                </div>
                <div v-else>
                    Up to date!
                </div>
            </div>

            <button
                class="btn-liq-primary mr-2 mt-4"
                @click="syncData()"
                @blur="blurHandler"
            >
                Sync now!
            </button>
            Last synced:
            <span v-if="lastSyncedAt">{{ $date(lastSyncedAt).fromNow() }}</span>
            <span v-else>never</span>
        </div>
    </t-dropdown>
</template>

<script>

import BaseIcon from "./BaseIcon";

export default {
    name: "SyncPopover",
    components: {
        BaseIcon
    },
    computed: {
        locallyCreatedItems() {
            return this.$store.getters['retailChain/locallyCreatedRetailChains'].concat(
                this.$store.getters['store/locallyCreatedStores'],
                this.$store.getters['fieldVisit/locallyCreatedFieldVisits'],
                this.$store.getters['user/locallyCreatedUsers'],
            );
        },
        locallyUpdatedItems() {
            return this.$store.getters['retailChain/locallyUpdatedRetailChains'].concat(
                this.$store.getters['store/locallyUpdatedStores'],
                this.$store.getters['fieldVisit/locallyUpdatedFieldVisits'],
                this.$store.getters['user/locallyUpdatedUsers'],
            );
        },
        locallyDeletedItems() {
            return this.$store.getters['store/locallyDeletedStores'];
        },
        hasUnsyncedChanges() {
            return this.locallyCreatedItems.length || this.locallyUpdatedItems.length || this.locallyDeletedItems.length;
        },
        syncStatusIcon() {
            if (this.hasUnsyncedChanges) {
                return 'exclamationCircle';
            }

            if (this.syncInProgress) {
                return 'refreshAnimation';
            }

            return 'check';
        },
        lastSyncedAt() {
            return this.$store.getters['sync/lastSyncedAt'];
        },
        syncInProgress() {
            return this.$store.getters['sync/syncInProgress'];
        },
    },
    methods: {
        syncData() {
            this.$store.dispatch("sync/syncData");
        },
        userFriendlyModifiedItemFields(modifiedFields) {
            let fieldNames = [];

            for (const modifiedFieldKey in modifiedFields) {
                const modifiedFieldValue = modifiedFields[modifiedFieldKey]

                if (modifiedFieldValue instanceof Object) {
                    fieldNames = fieldNames.concat(
                        this.userFriendlyModifiedItemFields(
                            modifiedFieldValue
                        )
                    );

                    continue;
                }

                fieldNames.push(modifiedFieldKey);
            }

            return fieldNames;
        },
    },
};
</script>
