<template>
    <t-card>
        <h2 class="border-b border-edge-primary pb-4 mt-0">
            {{ label }}
        </h2>
        <dl>
            <template v-for="(definitionItem, index) in definitionList">
                <dt :key="index + '_dt'" class="float-left w-1/2 sm:w-1/3 clear-left mt-4">
                    {{ definitionItem.label }}
                </dt>
                <dd :key="index + '_dd'" class="float-left w-1/2 sm:w-2/3 text-bold-text font-semibold mt-4">
                    <slot :name="definitionItem.slot_name" />
                </dd>
            </template>
        </dl>
        <div class="clear-both" />
    </t-card>
</template>

<script>
export default {
    name: "DefinitionListCard",
    props: {
        label: {
            type: String,
            default: null,
        },
        definitionList: {
            type: Array,
            default: null,
        },
    },
}
</script>
